import React from "react";
import UI from "../../../../@components/UI";
import useService from "../../../useService";
import { ethers } from "ethers";
import eventArtifact from "../../../../abis/contracts/modules/event/Event.sol/Event.json";
import collectionMultiArtifact from "../../../../abis/contracts/modules/collectionNativeMultipleImages/CollectionNativeMultipleImages.sol/CollectionNativeMultipleImages.json";

import { useSnackbar } from "notistack";
import { useAccount } from "wagmi";
import useHandleErrors from "../../../../@components/hooks/useHandleErrors";
import customHooks from "../../../../@components/hooks";
import { useFormData } from "../../context";
import CustomHooks from "../../../../@components/hooks";

const Button = (props) => {
  const { applyCustomStyles, title, DAO, collection, collectionId, onSuccess } =
    props;
  const { data: formData } = useFormData();
  let query = CustomHooks.useQuery();
  const { isConnected } = useAccount();
  const signer = customHooks.useEthersSigner();
  const { enqueueSnackbar } = useSnackbar();
  const { handleMetamaskError, handleApiError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(false);
  const { prepareCollectionNFTUri } = UI.useIPFS();
  const collectionService = useService();
  const selectedNFT = query.get("selectedNFT");

  const { refetch: fetchRandomImageData } = customHooks.useFetch(
    "collections",
    () => collectionService.getNextCollectionImage(DAO?.id, collectionId),
    {
      enabled: false,
    }
  );

  const saveBuyer = async (ticketId, ticket_image_url) => {
    await collectionService
      .buyTicket(collectionId, {
        dao_id: DAO?.id,
        ticket_id: ticketId,
        ...(ticket_image_url ? { ticket_image_url } : {}),
        additional_info: {
          ...formData?.additional_info,
        },
      })
      .then(() => {
        enqueueSnackbar("The NFT was successfully purchased", {
          variant: "success",
        });
        setIsLoading(false);
        onSuccess();
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      });
  };

  const buyTicket = async () => {
    if (
      collection.has_multiple_images &&
      collection.manual_nft_selection === false
    ) {
      // if the collection has multiple images
      await buyMultiTicket();
    } else if (
      collection.has_multiple_images &&
      collection.manual_nft_selection === true
    ) {
      // if the collection has multiple images, and user manually selected NFT image
      await buyMultiTicketManualSelected();
    } else {
      // otherwise, we call buyStandardTicket
      await buyStandardTicket();
    }
  };

  const buyMultiTicketManualSelected = async () => {
    setIsLoading(true);

    try {
      const collectionContract = new ethers.Contract(
        collection.nft_address,
        collectionMultiArtifact.abi,
        signer
      );

      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      let buyTicketTxResponse;

      //find the nft in the collection under collection_images based on selectedNFT
      const nftData = collection?.collection_images.find(
        (nft) => parseInt(nft.id) === parseInt(selectedNFT)
      );

      const nftUri = await prepareCollectionNFTUri(collection, nftData, DAO);

      buyTicketTxResponse = await collectionContract.buyTicket(nftUri, {
        value: priceToBuy,
      });
      const buyTicketReceipt = await buyTicketTxResponse.wait(1);

      const ticketMintEvent = buyTicketReceipt.logs.find(
        (event) => event?.eventName === "TicketMint"
      );

      const ticketId = Number(ticketMintEvent.args[1]);

      await saveBuyer(ticketId, nftData.image_url);
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  const buyMultiTicket = async () => {
    setIsLoading(true);

    try {
      const collectionContract = new ethers.Contract(
        collection.nft_address,
        collectionMultiArtifact.abi,
        signer
      );

      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      let buyTicketTxResponse;

      await fetchRandomImageData()
        .then(async (response) => {
          const randomImageData = response.data;

          const nftUri = await prepareCollectionNFTUri(
            collection,
            randomImageData,
            DAO
          );
          try {
            buyTicketTxResponse = await collectionContract.buyTicket(nftUri, {
              value: priceToBuy,
            });
            const buyTicketReceipt = await buyTicketTxResponse.wait(1);

            const ticketMintEvent = buyTicketReceipt.logs.find(
              (event) => event?.eventName === "TicketMint"
            );

            const ticketId = Number(ticketMintEvent.args[1]);

            await saveBuyer(ticketId, randomImageData.image_url);
          } catch (error) {
            handleMetamaskError(error);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          handleApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  const buyStandardTicket = async () => {
    setIsLoading(true);
    try {
      const collectionContract = new ethers.Contract(
        collection.nft_address,
        eventArtifact.abi,
        signer
      );

      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      let buyTicketTxResponse;

      buyTicketTxResponse = await collectionContract.buyTicket({
        value: priceToBuy,
      });

      const buyTicketReceipt = await buyTicketTxResponse.wait(1);

      const ticketMintEvent = buyTicketReceipt.logs.find(
        (event) => event?.eventName === "TicketMint"
      );

      const ticketId = Number(ticketMintEvent.args[1]);

      await saveBuyer(ticketId);
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <UI.Busy.FullscreenIndicator show={isLoading} />
      <UI.Button
        disabled={!isConnected}
        sx={{
          mt: "10px",
          color: applyCustomStyles ? "#000" : "#fff",
          backgroundColor: applyCustomStyles
            ? `${DAO?.dao_style[0]?.data.background_color}`
            : "#000",
          "&:hover": {
            backgroundColor: applyCustomStyles
              ? `${DAO?.dao_style[0]?.data.background_color}`
              : "#944DFF",
            border: applyCustomStyles
              ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
              : "0px solid #944DFF",
          },
        }}
        type={"primary"}
        title={title}
        onClick={() => buyTicket()}
      />
    </>
  );
};

export default Button;
