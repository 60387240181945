import React from "react";
import CustomHooks from "../@components/hooks";
import useService from "./useService";
import { Box, Container, Typography } from "@mui/material";
import UI from "../@components/UI";
import Form from "./form";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import BackButton from "./BackButton";
import Success from "./form/success";
import Failed from "./form/failed";
import FormProvider from "./form/context";
import { useTranslation } from "react-i18next";

const Index = () => {
  let query = CustomHooks.useQuery();
  const { t } = useTranslation();
  const service = useService();
  const { brandId, collectionId } = useParams();
  const sourceOpener = query.get("source");
  const loggedInUserEmail = query.get("email");
  const stripePaymentSuccess = query.get("success");

  const stripePaymentFailed = query.get("failed");
  const stripePaymentCanceled = query.get("canceled");
  const manualNFTSelection = query.get("manualNFTSelection");
  const selectedNFT = query.get("selectedNFT");
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepName, setActiveStepName] = React.useState(null);
  const [showCustomStyles, setShowCustomStyles] = React.useState(true);
  const [hasRequestInfoFields, setHasRequestInfoFields] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showSuccessPage, setShowSuccessPage] = React.useState(false);
  const [showFailedPage, setShowFailedPage] = React.useState(false);
  const [manualSelectedNFT, setManualSelectedNFT] = React.useState(null);

  const localStorageSelectedNft = JSON.parse(
    localStorage.getItem("local_storage_manual_selected_nft")
  );

  React.useEffect(() => {
    if (stripePaymentSuccess) {
      setShowSuccessPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripePaymentSuccess]);

  React.useEffect(() => {
    if (stripePaymentFailed) {
      setShowFailedPage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripePaymentFailed]);

  React.useEffect(() => {
    if (stripePaymentCanceled) {
      window.location.href = localStorage.getItem("prev_location");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripePaymentCanceled]);

  React.useEffect(() => {
    if (sourceOpener === "qvrse") {
      localStorage.setItem("source", sourceOpener);
      setShowCustomStyles(false);
    }
  }, [sourceOpener]);

  const { data: DAO, isLoading: loadingBrand } = CustomHooks.useFetch(
    "dao",
    () => service.dao(brandId)
  );
  const { data: collection, isLoading: loadingCollection } =
    CustomHooks.useFetch("collection", () =>
      service.collection(brandId, collectionId)
    );

  const getManualSelectedNFT = (collection) => {
    if (manualNFTSelection && manualNFTSelection.toLowerCase() === "true") {
      // find the nft in the collection under collection_images based on selectedNFT
      const nft = collection?.collection_images.find(
        (nft) => parseInt(nft.id) === parseInt(selectedNFT)
      );
      setManualSelectedNFT(nft);
      localStorage.setItem(
        "local_storage_manual_selected_nft",
        JSON.stringify(nft)
      );
    }
  };

  //Check if collection has any request info fields
  React.useEffect(() => {
    setIsLoading(true);
    if (collection) {
      getManualSelectedNFT(collection);

      const containsFields = collection.utilities.some(
        (utility) =>
          utility.request_info_fields && utility.request_info_fields.length > 0
      );
      setHasRequestInfoFields(containsFields);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  return (
    <Box sx={{ BackgroundColor: "rgba(255,255,255,0.8)" }}>
      <UI.Busy.FullscreenIndicator show={loadingBrand || loadingCollection}>
        <Typography
          variant={"subtitle2"}
          sx={{
            color: "#fff",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontSize: "16px !important",
          }}
        >
          {t("fullScreenIndicator.qvrsePayments.loading")}
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{
            color: "#fff",
            textAlign: "center",
            fontFamily: "Open Sans",
            fontSize: "16px !important",
          }}
        >
          {t("fullScreenIndicator.qvrsePayments.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>

      <Header
        DAO={DAO}
        applyCustomStyles={showCustomStyles}
        hasRequestInfoFields={hasRequestInfoFields}
      />

      {(!loadingBrand || !loadingCollection || !isLoading) && (
        <Container maxWidth={"lg"} sx={{ p: { mobile: "0px" } }}>
          <Box
            sx={{
              px: { md: "117px", mobile: "0px" },
              display: { md: "flex", mobile: "none" },
              mt: { md: "0px", mobile: "50px" },
            }}
          >
            <Box>
              {activeStepName !== null && activeStepName !== "success" && (
                <Box sx={{ mb: "15px" }}>
                  <BackButton
                    source={sourceOpener}
                    hasRequestInfoFields={hasRequestInfoFields}
                    DAO={DAO}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    activeStepName={activeStepName}
                    loggedInUserEmail={loggedInUserEmail}
                  />
                </Box>
              )}
              <Typography
                variant={"h2"}
                sx={{
                  fontSize: "16px !important",
                  color: "#000",
                }}
              >
                {collection?.name}
              </Typography>
              {collection?.currency && (
                <Box
                  sx={{
                    width: "15px",
                    height: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {collection?.free_nft_gas_fee_payed_by_system === false && (
                    <UI.PaymentIcon currency={collection?.currency} />
                  )}
                  {collection?.total_price_with_tax > "0.00" && (
                    <Typography
                      variant={"h2"}
                      sx={{
                        ml: "9px",
                        fontSize: "16px !important",
                        color: "#000",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {collection?.total_price_with_tax}
                    </Typography>
                  )}
                  {collection?.total_price_with_tax === "0.00" && (
                    <Typography
                      variant={"h2"}
                      sx={{
                        fontSize: "14px !important",
                        color: "#7f7f7f",
                        fontFamily: "Open Sans",
                        ml: "9px",
                      }}
                    >
                      {t("commons.free")}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              px: { md: "117px", mobile: "0px" },
              my: { md: "40px", mobile: "0px" },
              display: { md: "flex", mobile: "block" },
            }}
          >
            <Box
              sx={{
                flex: "1",
                display: { md: "flex", mobile: "none" },
                flexDirection: "column",
              }}
            >
              {manualSelectedNFT === null && (
                <Box
                  sx={{
                    mt: "9px",
                    mb: "12px",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "350px",
                  }}
                >
                  {(
                    localStorageSelectedNft !== null
                      ? localStorageSelectedNft.file_type?.startsWith("video")
                      : collection?.nft_file_type?.startsWith("video")
                  ) ? (
                    <video
                      playsInline
                      width={"fit-content"}
                      height={"100%"}
                      autoplay="autoplay"
                      style={{
                        maxHeight: "350px",
                        borderRadius: "5px",
                      }}
                      loop
                      muted
                    >
                      <source
                        src={
                          localStorageSelectedNft?.image_url ||
                          collection?.nft_image_url
                        }
                      />
                    </video>
                  ) : (
                    <UI.ImageWithSkeleton
                      src={
                        localStorageSelectedNft?.image_url ||
                        collection?.nft_image_url
                      }
                      sx={{ width: "260px", height: "auto", borderRadius: "5px" }}
                    />
                  )}
                </Box>
              )}
              {manualSelectedNFT !== null && (
                <Box
                  sx={{
                    mt: "9px",
                    mb: "12px",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "350px",
                  }}
                >
                  {manualSelectedNFT?.file_type?.startsWith("video") ? (
                    <video
                      playsInline
                      width={"fit-content"}
                      height={"100%"}
                      autoplay="autoplay"
                      style={{
                        maxHeight: "350px",
                        borderRadius: "5px",
                      }}
                      loop
                      muted
                    >
                      <source
                        src={
                          localStorageSelectedNft.image_url ||
                          manualSelectedNFT?.image_url
                        }
                      />
                    </video>
                  ) : (
                    <UI.ImageWithSkeleton
                      src={
                        localStorageSelectedNft.image_url ||
                        manualSelectedNFT?.image_url
                      }
                      sx={{ width: "260px", height: "auto", borderRadius: "5px" }}
                    />
                  )}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: { md: "flex", mobile: "none" },
                width: "1.5px",
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                mt: "-26px",
              }}
            />
            <Box sx={{ flex: "1" }}>
              {showSuccessPage === true && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Success
                    applyCustomStyles={showCustomStyles}
                    collection={collection}
                    DAO={DAO}
                  />
                </Box>
              )}
              {showFailedPage === true && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Failed
                    applyCustomStyles={showCustomStyles}
                    collection={collection}
                    DAO={DAO}
                  />
                </Box>
              )}
              {showSuccessPage === false && showFailedPage === false && (
                <FormProvider>
                  <Form
                    onActiveStepChange={(activeStep) => {
                      setActiveStepName(activeStep);
                    }}
                    hasRequestInfoFields={hasRequestInfoFields}
                    applyCustomStyles={showCustomStyles}
                    DAO={DAO}
                    collection={collection}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                  />
                </FormProvider>
              )}
            </Box>
          </Box>
        </Container>
      )}
      <Footer />
    </Box>
  );
};

export default Index;
