import React from "react";
import { Box, Typography } from "@mui/material";
import UI from "../../../../@components/UI";
import Button from "./Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAccount, useDisconnect } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useFormData } from "../../context";
import WrongWalletDialog from "./WrongWalletDialog";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FreeMint from "../fiat/freeMint";
import { useTranslation } from "react-i18next";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  nextStep,
  previouseStep,
}) => {
  const { t } = useTranslation();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();
  const { isConnected, address } = useAccount();
  const { data: formData } = useFormData();
  const [showWrongWalletDialog, setShowWrongWalletDialog] =
    React.useState(false);

  const checkForValidAddress = () => {
    const hasValidWalletAddress =
      formData?.wallet_address !== null && formData?.wallet_address !== "";

    if (isConnected && hasValidWalletAddress) {
      if (address !== formData?.wallet_address) {
        setShowWrongWalletDialog(true);
      }
    }
  };

  React.useEffect(() => {
    checkForValidAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, formData, address]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { md: "370px", mobile: "100%" },
      }}
    >
      {showWrongWalletDialog && (
        <WrongWalletDialog
          connectedMetamaskWallet={address}
          userWalletAddress={formData?.wallet_address}
          onClose={() => {
            disconnect();
            setShowWrongWalletDialog(false);
          }}
        />
      )}
      <MobileHeader
        t={t}
        collection={collection}
        applyCustomStyles={applyCustomStyles}
        DAO={DAO}
        previouseStep={previouseStep}
      />
      <Box
        sx={{
          mt: { mobile: "30px", md: "0px" },
          width: { md: "370px", mobile: "unset" },
          px: { mobile: "20px", md: "0px" },
        }}
      >
        {collection?.total_price_with_tax !== "0.00" && (
          //PAID NFT
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "22px !important",
              color: "#000",
              textAlign: "center",
            }}
          >
            {!isConnected && t("payment.crypto.connectYourWallet")}
            {isConnected && t("payment.crypto.confirmPayment")}
          </Typography>
        )}

        {collection?.total_price_with_tax === "0.00" && (
          //Free NFT
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "22px !important",
              color: "#000",
              textAlign: "center",
            }}
          >
            {collection?.free_nft_gas_fee_payed_by_system === false &&
              isConnected &&
              t("payment.crypto.connectYourWallet")}
            {collection?.free_nft_gas_fee_payed_by_system === false &&
              !isConnected &&
              t("payment.crypto.connectWallet")}
            {collection?.free_nft_gas_fee_payed_by_system === true &&
              isConnected &&
              t("payment.crypto.confirmChoice")}
            {collection?.free_nft_gas_fee_payed_by_system === true &&
              !isConnected &&
              t("payment.crypto.confirmChoice")}
          </Typography>
        )}
        {collection?.total_price_with_tax !== "0.00" && (
          <Typography
            variant={"subtitle1"}
            sx={{
              mt: "18px",
              mb: "30px",
              fontFamily: "Open Sans",
              fontSize: "14px !important",
              color: "#000",
              textAlign: "center",
            }}
          >
            {t("payment.crypto.info")}
          </Typography>
        )}
        {collection?.total_price_with_tax === "0.00" && (
          <Typography
            variant={"subtitle1"}
            sx={{
              mt: "18px",
              mb: "30px",
              fontFamily: "Open Sans",
              fontSize: "14px !important",
              color: "#000",
              textAlign: "center",
            }}
          >
            {t("payment.crypto.info2")}
          </Typography>
        )}
        {collection?.total_price_with_tax === "0.00" &&
          isConnected &&
          collection?.free_nft_gas_fee_payed_by_system === false && (
            <Button
              applyCustomStyles={applyCustomStyles}
              title={t("payment.crypto.button")}
              collection={collection}
              collectionId={collection?.id}
              DAO={DAO}
              onSuccess={() => {
                nextStep();
              }}
              startIcon={<AccountBalanceWalletIcon />}
            />
          )}
        {collection?.total_price_with_tax === "0.00" &&
          collection?.free_nft_gas_fee_payed_by_system === true && (
            <Box
              sx={{ display: "flex", mt: "20px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <FreeMint.Button
                DAO={DAO}
                applyCustomStyles={applyCustomStyles}
                orderId={formData?.order_id}
                isFreeMintNoWallet={true}
                title={t("payment.crypto.button")}
                collection={collection}
                onSuccess={() => {
                  nextStep();
                }}
              />
            </Box>
          )}

        {isConnected && collection?.total_price_with_tax > "0.00" && (
          <Button
            applyCustomStyles={applyCustomStyles}
            title={t("payment.crypto.button2")}
            onSuccess={() => {
              nextStep();
            }}
            DAO={DAO}
            collection={collection}
            collectionId={collection?.id}
          />
        )}
        {!isConnected &&
          formData?.wallet_address &&
          collection?.free_nft_gas_fee_payed_by_system === false && (
            <UI.Button
              type={"primary"}
              sx={{
                color: applyCustomStyles ? "#000" : "#fff",
                backgroundColor: applyCustomStyles
                  ? `${DAO?.dao_style[0]?.data.background_color}`
                  : "#000",
                "&:hover": {
                  backgroundColor: applyCustomStyles
                    ? `${DAO?.dao_style[0]?.data.background_color}`
                    : "#944DFF",
                  border: applyCustomStyles
                    ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                    : "0px solid #944DFF",
                },
              }}
              title={t("payment.crypto.button3")}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openConnectModal();
              }}
            />
          )}
      </Box>
    </Box>
  );
};

const MobileHeader = ({
  t,
  applyCustomStyles,
  DAO,
  collection,
  previouseStep,
}) => {
  return (
    <Box
      onClick={previouseStep}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#000",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height: "20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        {collection?.total_price_with_tax === "0.00"
          ? t("payment.mobileHeader")
          : t("payment.mobileHeader2")}
      </Typography>
    </Box>
  );
};

export default Index;
