import React from "react";
import { SignerContextProvider } from "./@components/contextAPI/SignerContext";

const TurnkeyIframeContainerId = "turnkey-iframe-container-id";
const TurnkeyIframeElementId = "turnkey-iframe-element-id";

const AlchemyWrapper = ({ children }) => {
  const [clientConfig] = React.useState({
    connection: {
      rpcUrl: window.env.GATEWAY + "/api/rpc",
    },
    rpId: "qvrse.io",
    iframeConfig: {
      iframeContainerId: TurnkeyIframeContainerId,
      iframeElementId: TurnkeyIframeElementId,
    },
  });
  return (
    <SignerContextProvider client={clientConfig}>
      {children}
    </SignerContextProvider>
  );
};

export default AlchemyWrapper;
