import React from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import truncateEthAddress from "truncate-eth-address";
import { useTranslation } from "react-i18next";

const WrongWalletDialog = ({
  userWalletAddress,
  connectedMetamaskWallet,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          width: "480px",
          p: { md: "50px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: (theme) => theme.palette.colors.darkGray,
        },
      }}
      onClose={onClose}
      open={true}
    >
      <Typography variant={"h2"}>
        {t("payment.crypto.wrongWalletDialog.title")}
      </Typography>
      <Box sx={{ mt: "40px" }}>
        <Typography variant={"subtitle1"}>
          {t("payment.crypto.wrongWalletDialog.subtitle", {
            address1: truncateEthAddress(userWalletAddress || ""),
            address2: truncateEthAddress(connectedMetamaskWallet || ""),
          })}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: "50px" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <UI.Button
            type={"primary"}
            title={t("payment.crypto.wrongWalletDialog.button")}
            onClick={() => onClose()}
            sx={{
              width: "150px",
              height: "50px",
              filter: "drop-shadow(0px 0px 30px rgba(138, 38, 255, 0.5));",
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default WrongWalletDialog;
