import PaymentIcon from "./PaymentIcon";
import ImageWithSkeleton from "./ImageWithSkeleton";
import Button from "./Button";
import Inputs from "./inputs";
import Busy from "./busy";
import CopyToClipboard from "./CopyToClipboard";
import CopyToClipboardPassword from "./CopyToClipboardPassword";
import Tooltip from "./Tooltip";
import useIPFS from "./useIPFS";

const UI = {
  PaymentIcon,
  ImageWithSkeleton,
  Inputs,
  Busy,
  Button,
  CopyToClipboard,
  CopyToClipboardPassword,
  Tooltip,
  useIPFS,
};

export default UI;
