import React from "react";

import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import CustomHooks from "../../../@components/hooks";
import { useSignerContext } from "../../../@components/contextAPI/SignerContext";
import useAuth from "../../../@components/auth/useAuth";
import Link from "@mui/material/Link";
import { createMultiOwnerModularAccount } from "@alchemy/aa-accounts";
import { custom, http } from "viem";
import { createBundlerClient, polygonMumbai } from "@alchemy/aa-core";
import Slide from "@mui/material/Slide";
import secureAccountIcon from "../../../static/secure-account.png";
import faceRecognitionIcon from "../../../static/face-recognition.png";

const publicClient = createBundlerClient({
  chain: polygonMumbai,
  transport: http(window.env.GATEWAY + "/api/rpc"),
});

const CreateSmartAccountDialog = ({
  toggleOpen,
  userEmail,
  userId,
  onSuccess,
}) => {
  const { signer, refetchUserDetails } = useSignerContext();
  const { addSmartAccountToExistingUser } = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsloading] = React.useState(false);

  const { mutate } = CustomHooks.usePost(signer?.authenticate, {
    onSuccess: async (data) => {
      await signer.getAuthDetails().catch(() => {
        return null;
      });

      const smartAccount = await createMultiOwnerModularAccount({
        transport: custom(publicClient),
        chain: polygonMumbai,
        signer: signer,
      });

      await addSmartAccountToExistingUser({
        smart_address: smartAccount.address,
      });
      setIsloading(false);
      refetchUserDetails();
      onSuccess(smartAccount.address);
      handleClose();
    },
    onError: (e) => {
      console.error("Failed to login", e);
      setIsloading(false);
    },
  });

  React.useEffect(() => {
    toggleOpen && setOpen(!open);
    // eslint-disable-next-line
  }, [toggleOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <MaterialDialog
      // TransitionComponent={Transition}
      PaperProps={{
        sx: {
          // Default styles that apply to all breakpoints
          position: "absolute", // Needed to allow for custom positioning
          textAlign: "center",
          borderTopRightRadius: "35px",
          borderTopLeftRadius: "35px",
          backgroundColor: (theme) => theme.palette.colors.darkGray,

          // Styles for mobile and smaller screens
          // This makes the dialog stick to the bottom and occupy 50% of the screen height on mobile devices
          "@media (max-width: 600px)": {
            maxHeight: "50vh", // Limits the dialog height to 50% of the viewport height
            width: "100%", // Dialog occupies the full width of the screen
            m: 0, // Removes margin
            mt: "auto", // Pushes the dialog to the bottom of the screen
            bottom: 0, // Aligns to the bottom of the screen
            padding: "28px 10px 30px", // Mobile-specific padding
          },

          // Styles for desktop and larger screens
          // Reverts to the original design on desktops
          "@media (min-width: 600px)": {
            borderRadius: "15px",
            position: "relative", // Use relative positioning on desktop
            backgroundColor: (theme) => theme.palette.colors.darkGray,
            padding: "40px 60px 50px", // Desktop-specific padding
            width: "650px", // Fixed width for desktop
            textAlign: "center",
            margin: "10px", // Margin around the dialog on desktop
          },
        },
      }}
      open={open}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h3"} sx={{ textAlign: "center" }}>
              {"Secure your Account"}
            </Typography>
            <Box
              component={"img"}
              src={secureAccountIcon}
              alt={"Secure Account"}
              sx={{
                width: "60px",
                margin: "0 auto",
                mt: "20px",
                filter: "invert(1)",
              }}
            />
            <Typography variant={"subtitle2"} sx={{ mt: "16px" }}>
              {
                "Passkeys are a secure way to access your account. They are unique to you and can be used to access your account from any device."
              }
            </Typography>
            <Box
              sx={{
                mt: { md: "34px", mobile: "26px" },
                px: "15px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UI.Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsloading(true);
                  mutate({
                    type: "passkey",
                    createNew: true,
                    username: userEmail,
                    creationOpts: {
                      publicKey: {
                        rp: {
                          id: "qvrse.io",
                          name: "QVRS",
                        },
                      },
                    },
                  });
                }}
                sx={{ width: "340px" }}
                title={
                  <>
                    <Box
                      component={"img"}
                      src={faceRecognitionIcon}
                      alt={"Face Recognition"}
                      sx={{ width: "20px", filter: "invert(1)", mr: "10px" }}
                    />
                    Use Passkey
                  </>
                }
                type={"primary"}
              />
              <Box sx={{ mt: { mobile: "10px", md: "10px" }, px: "10px" }}>
                <Typography variant={"subtitle3"} sx={{ textAlign: "center" }}>
                  {t("commons.support")}{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      transition: "all 0.2s ease-in-out",
                      color: (theme) => theme.palette.text.link,
                      "&:hover": {
                        color: (theme) => theme.palette.text.linkHover,
                      },
                    }}
                    target="_blank"
                    href={"mailto: mainUtility@qvrse.io"}
                  >
                    info@qvrse.io
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {"Upgrading your account"}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("profile.dialogs.resetPasswordDialog.loading2")}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default CreateSmartAccountDialog;
