import React from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import UI from "../../../@components/UI";
import { Alert } from "@mui/lab";
import CustomHooks from "../../../@components/hooks";
import useService from "../../useService";
import { useFormData } from "../context";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  nextStep,
  previouseStep,
  autoLogin,
}) => {
  const { t } = useTranslation();
  let query = CustomHooks.useQuery();
  const loggedInUserEmail = query.get("email");
  const [requestInfoFields, setRequestInfoFields] = React.useState([]);
  const service = useService();

  const { setFormValues: setFormData } = useFormData();

  const { mutate: registerUser } = CustomHooks.usePost(service.registerUser, {
    onSuccess: (response) => {
      const { access, user } = response;
      const { id: userId, address: walletAddress } = user;
      setFormData({ user_id: userId, wallet_address: walletAddress });
      localStorage.setItem("access_token", access);
    },
  });

  React.useEffect(() => {
    if (collection?.utilities.length === 0) return;

    const fields = collection?.utilities
      .flatMap((utility) => utility.request_info_fields)
      .filter((field) => field !== undefined);

    setRequestInfoFields(fields || []);
  }, [collection]);

  React.useEffect(() => {
    const autoLoginUser = async () => {
      setFormData({ user_email: loggedInUserEmail });
      await registerUser(loggedInUserEmail);
    };
    if (autoLogin && loggedInUserEmail) {
      autoLoginUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLogin, loggedInUserEmail]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  const { mutate: newOrder } = CustomHooks.usePost(service.createOrder, {
    onSuccess: (response) => {
      const { id: orderId } = response;
      setFormData({ order_id: orderId });
      nextStep();
    },
  });

  const onSubmit = async (form) => {
    const data = {
      collection_id: collection.id,
      additional_info: { ...form },
    };

    setFormData({ additional_info: { ...form } });
    await newOrder({
      dao_id: DAO.id,
      collection_id: collection.id,
      data: data,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { md: "370px", mobile: "100%" },
      }}
    >
      <MobileHeader
        applyCustomStyles={applyCustomStyles}
        DAO={DAO}
        previouseStep={previouseStep}
      />
      <Box
        sx={{
          mt: { mobile: "30px", md: "0px" },
          width: { md: "370px", mobile: "unset" },
          px: { mobile: "20px", md: "0px" },
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "22px !important",
            color: "#000",
            textAlign: "center",
          }}
        >
          {t("requestInfo.title")}
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{
            mt: "18px",
            mb: "30px",
            fontFamily: "Open Sans",
            fontSize: "14px !important",
            color: "#000",
            textAlign: "center",
          }}
        >
          {t("requestInfo.subtitle")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {requestInfoFields && requestInfoFields?.length > 0 && (
            <Box sx={{ mt: { md: "30px", mobile: "20px" } }}>
              {requestInfoFields?.map((data) => {
                return (
                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                    key={data?.id}
                  >
                    <Box sx={{ display: "flex", mb: { mobile: "10px", md: "5px" } }}>
                      <Typography
                        variant={"h4"}
                        sx={{
                          color: "#000",
                          fontSize: "14px !important",
                          fontFamily: "Open Sans",
                        }}
                      >
                        {data?.title}
                      </Typography>
                      <UI.Tooltip>{data?.tooltip}</UI.Tooltip>
                    </Box>
                    <Controller
                      name={data?.title}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <UI.Inputs.TextField
                          {...field}
                          backgroundColor={"#ffffff"}
                          placeholder={data?.placeholder}
                        />
                      )}
                    />
                    <Typography variant={"subtitle1"}>
                      {errors[data?.title]?.type === "required" && (
                        <Alert
                          sx={{
                            color: (theme) => theme.palette.colors.darkRed,
                            mt: { mobile: "8px", md: "8px" },
                            mb: { mobile: "8px", md: "0px" },
                            borderRadius: "5px",
                          }}
                          variant="outlined"
                          severity="error"
                        >
                          <Typography
                            variant={"subtitle2"}
                            sx={{
                              fontSize: "12px !important",
                              color: (theme) => theme.palette.colors.darkRed,
                              fontFamily: "Open Sans",
                              fontWeight: "bold",
                              ml: "0px",
                              mt: "-1px",
                            }}
                          >
                            {t("requestInfo.error.required", {
                              title: data?.title,
                            })}
                          </Typography>
                        </Alert>
                      )}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          {requestInfoFields && requestInfoFields?.length === 0 && (
            <Box sx={{ my: { md: "40px", mobile: "20px" } }}>
              <Typography
                variant={"subtitle1"}
                sx={{
                  fontSize: "12px !important",
                  color: "#000",
                  fontWeight: "bold",
                  lineHeight: "18px",
                  mb: "45px",
                  textAlign: "center",
                }}
              >
                {t("requestInfo.info")}
              </Typography>
            </Box>
          )}
          <UI.Button
            type={"primary"}
            sx={{
              color: applyCustomStyles ? "#000" : "#fff",
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#000",
              "&:hover": {
                backgroundColor: applyCustomStyles
                  ? `${DAO?.dao_style[0]?.data.background_color}`
                  : "#944DFF",
                border: applyCustomStyles
                  ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                  : "0px solid #944DFF",
              },
            }}
            title={t("buttons.continue")}
          />
        </form>
      </Box>
    </Box>
  );
};

const MobileHeader = ({ applyCustomStyles, DAO, previouseStep }) => {
  const { t } = useTranslation();
  return (
    <Box
      onClick={() => {
        previouseStep();
      }}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#000",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height: "20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        {t("requestInfo.mobileHeader")}
      </Typography>
    </Box>
  );
};

export default Index;
