import { createMultiOwnerModularAccount } from "@alchemy/aa-accounts";
import { createBundlerClient, polygonMumbai } from "@alchemy/aa-core";
import { AlchemySigner } from "@alchemy/aa-alchemy";
import { useQuery } from "react-query";
import { createContext, useContext, useState } from "react";
import { custom, http } from "viem";
import { useLocation } from "react-router-dom";

const publicClient = createBundlerClient({
  chain: polygonMumbai,
  transport: http(window.env.GATEWAY + "/api/rpc"),
});

const SignerContext = createContext(undefined);

export const useSignerContext = () => {
  const context = useContext(SignerContext);

  if (context === undefined) {
    throw new Error(
      "useSignerContext must be used within a SignerContextProvider"
    );
  }

  return context;
};

export const SignerContextProvider = ({ children, ...signerConfig }) => {
  const [signer] = useState(() => {
    if (typeof window === "undefined") return undefined;

    const iframeContainer = document.createElement("div");
    iframeContainer.id = signerConfig.client.iframeConfig.iframeContainerId;
    iframeContainer.style.display = "none";
    document.body.appendChild(iframeContainer);
    console.log("signerConfig: ", signerConfig);
    return new AlchemySigner(signerConfig);
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  console.log("searchParams: ", searchParams.get("bundle"));
  const {
    data = { user: null, account: null },
    isLoading: isLoadingUser,
    refetch: refetchUserDetails,
  } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      if (searchParams.get("bundle") != null) {
        await signer.authenticate({
          type: "email",
          bundle: searchParams.get("bundle"),
        });
      }
      const user = await signer.getAuthDetails().catch(() => {
        return null;
      });

      const account = user
        ? await createMultiOwnerModularAccount({
            transport: custom(publicClient),
            chain: polygonMumbai,
            signer: signer,
          })
        : null;

      return {
        account,
        user,
      };
    },
  });

  return (
    <SignerContext.Provider
      value={{
        signer: signer,
        user: data.user,
        account: data.account,
        isLoadingUser,
        refetchUserDetails,
      }}
    >
      {children}
    </SignerContext.Provider>
  );
};
