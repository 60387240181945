import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard as ReactCopyToClipBoard } from "react-copy-to-clipboard";
import MobileTooltip from "./MobileTooltip";

const CopyToClipboard = ({
  label,
  value,
  showBorder = false,
  showIcon = true,
  overrideStyles,
}) => {
  const [tooltipMessage, setTooltipMessage] =
    React.useState("Copy to Clipboard");

  return (
    <React.Fragment>
      <Box sx={{ display: { md: "block", mobile: "none" } }}>
        {value && label ? (
          <ReactCopyToClipBoard
            text={value}
            onCopy={() => {
              setTooltipMessage("Copied!");
              setTimeout(() => setTooltipMessage("Copy to Clipboard"), 1000);
            }}
          >
            <Tooltip title={tooltipMessage} disableFocusListener arrow>
              <Chip
                sx={
                  overrideStyles
                    ? overrideStyles
                    : {
                        width: "100%",
                        p: "15px",
                        borderRadius: "0px",
                        border: showBorder
                          ? "1px solid #5B5E72"
                          : "1px solid #ffffff00",
                        backgroundColor: "transparent",
                        "&:hover": {
                          transition: "all 0.2s ease",
                          border: "1px solid #5B5E72",
                        },
                      }
                }
                variant="contained"
                clickable
                icon={
                  showIcon && (
                    <ContentCopyIcon
                      sx={{ fontSize: "15px", color: "white !important" }}
                    />
                  )
                }
                label={label}
              />
            </Tooltip>
          </ReactCopyToClipBoard>
        ) : (
          <Skeleton variant={"rectangular"} height={"20px"} width={"136px"} />
        )}
      </Box>
      <Box sx={{ display: { md: "none", mobile: "block" } }}>
        <MobileTooltip title={"Copied!"}>
          {value && label ? (
            <ReactCopyToClipBoard text={value}>
              <Chip
                sx={
                  overrideStyles
                    ? overrideStyles
                    : {
                        borderRadius: "0px",
                        border: showBorder
                          ? "1px solid #ffffff30"
                          : "1px solid #ffffff00",
                        backgroundColor: "transparent",
                        "&:hover": {
                          transition: "all 0.2s ease",
                          border: "1px solid #ffffff30",
                        },
                      }
                }
                variant="contained"
                clickable
                icon={
                  showIcon && (
                    <ContentCopyIcon
                      sx={{ fontSize: "15px", color: "white !important" }}
                    />
                  )
                }
                label={label}
              />
            </ReactCopyToClipBoard>
          ) : (
            <Skeleton variant={"rectangular"} height={"20px"} width={"136px"} />
          )}
        </MobileTooltip>
      </Box>
    </React.Fragment>
  );
};

export default CopyToClipboard;
